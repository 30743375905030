$('.attachment-field').each(function () {
	let dt = new DataTransfer();

	$(this).find('.file-input').on('change', function (e) {
		let filesList = $(this).closest(".attachment-field").find(".files-names");
		for (let file of this.files) {
			let file_first_svg = `<svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
									<path d="M15.6278 5.80298L12.1128 1.84464C12.0388 1.76263 11.9484 1.69702 11.8476 1.65203C11.7467 1.60703 11.6375 1.58366 11.527 1.58339H5.19366C4.93063 1.58026 4.66955 1.62896 4.42535 1.72673C4.18114 1.8245 3.95858 1.96941 3.77039 2.1532C3.58219 2.33699 3.43204 2.55604 3.32851 2.79786C3.22498 3.03968 3.17009 3.29953 3.16699 3.56256V15.4376C3.17009 15.7006 3.22498 15.9604 3.32851 16.2023C3.43204 16.4441 3.58219 16.6631 3.77039 16.8469C3.95858 17.0307 4.18114 17.1756 4.42535 17.2734C4.66955 17.3712 4.93063 17.4199 5.19366 17.4167H13.807C14.07 17.4199 14.3311 17.3712 14.5753 17.2734C14.8195 17.1756 15.0421 17.0307 15.2303 16.8469C15.4185 16.6631 15.5686 16.4441 15.6721 16.2023C15.7757 15.9604 15.8306 15.7006 15.8337 15.4376V6.33339C15.8331 6.13719 15.7598 5.94817 15.6278 5.80298ZM11.0837 3.16673L14.0445 6.33339H11.6695C11.5866 6.32839 11.5055 6.30692 11.431 6.27024C11.3565 6.23357 11.29 6.18242 11.2355 6.11977C11.1809 6.05713 11.1394 5.98426 11.1134 5.9054C11.0873 5.82654 11.0772 5.74328 11.0837 5.66048V3.16673Z" fill="#1D92F1"/>
								</svg>`
			if (filesList[0].getAttribute('data-icon')) {
				console.log('new icon');
				file_first_svg = `<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
				<path d="M7.35929 1.83203C5.33438 1.83203 3.69263 3.47378 3.69263 5.49869V16.4987C3.69263 18.5236 5.33438 20.1653 7.35929 20.1653H14.6926C16.7175 20.1653 18.3593 18.5236 18.3593 16.4987V5.49869C18.3593 3.47378 16.7175 1.83203 14.6926 1.83203H7.35929ZM8.27596 6.41535C8.78196 6.41535 9.19263 6.82602 9.19263 7.33202C9.19263 7.83802 8.78196 8.24868 8.27596 8.24868C7.76996 8.24868 7.35929 7.83802 7.35929 7.33202C7.35929 6.82602 7.76996 6.41535 8.27596 6.41535ZM13.776 10.9987C14.072 10.9987 14.7696 11.8402 15.4663 13.2335C15.7532 13.8064 16.0273 14.4462 16.2684 15.0668C16.3866 15.3712 16.4682 15.5582 16.526 15.725V16.4987C16.526 17.4483 15.7761 18.332 14.6926 18.332H7.35929C6.30146 18.332 5.5883 17.4804 5.55438 16.5555C5.5828 16.4583 5.58921 16.3666 5.64054 16.2127C5.77071 15.8212 5.94855 15.4252 6.1273 15.0668C6.55997 14.2024 7.01096 13.7487 7.35929 13.7487C7.79013 13.7487 8.2283 14.0924 8.64813 14.7222C8.81772 14.9761 8.98363 15.2465 9.10647 15.5252C9.1798 15.6892 9.23021 15.8093 9.24946 15.868C9.52812 16.704 10.6905 16.704 10.9691 15.868C11.0214 15.7103 11.1396 15.4381 11.2835 15.0668C11.5246 14.4462 11.7987 13.8064 12.0856 13.2335C12.7823 11.8402 13.4799 10.9987 13.776 10.9987Z" fill="#364670"/>
			  </svg>`
			}
			let fileBloc = `
                <a href="#" class="lesson-exercise__file">
                    <div class="lesson-exercise__file__svg">
					${file_first_svg}
                    </div>
                    <div class="lesson-exercise__file__text file-name">${file.name}</div>
                    <div class="lesson-exercise__file__del file-delete">
					<svg class="del-svg" xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
					<path d="M5.33171 1.80212C3.87817 1.80212 2.66504 3.1441 2.66504 4.61877V5.14078C2.66504 5.50944 2.96351 5.80809 3.33177 5.80809L3.33171 12.4688C3.33171 13.9308 4.53484 15.1354 5.99837 15.1354H9.99837C11.4617 15.1354 12.665 13.9348 12.665 12.4688L12.6657 5.80809C13.0337 5.80809 13.3324 5.50944 13.3324 5.14078V4.47277C13.3324 3.00877 12.127 1.80212 10.665 1.80212H5.33171ZM5.33171 3.13545L10.6864 3.15812C11.4117 3.15812 11.9984 3.74212 11.9984 4.46878C10.7957 4.46878 5.28264 4.46878 3.99837 4.46878C4.08104 3.77612 4.68817 3.13545 5.33171 3.13545ZM4.66504 5.80211H11.3317V12.4688C11.3317 13.2014 10.7177 13.8021 9.99837 13.8021H5.99837C5.26384 13.8021 4.66504 13.1894 4.66504 12.4688V5.80211ZM6.66497 7.12677C6.29677 7.12677 5.99824 7.42612 5.99831 7.79478V11.8008C5.99831 12.1701 6.29677 12.4688 6.66497 12.4688C7.03304 12.4688 7.33171 12.1701 7.33171 11.8008V7.79478C7.33171 7.42612 7.03304 7.12677 6.66497 7.12677ZM9.33171 7.12677C8.96371 7.12677 8.66504 7.42612 8.66504 7.79478V11.8008C8.66504 12.1701 8.96371 12.4688 9.33171 12.4688C9.69971 12.4688 9.99837 12.1701 9.99837 11.8008V7.79478C9.99837 7.42612 9.69971 7.12677 9.33171 7.12677Z" fill="#FF5C5C"/>
				</svg>
                    </div>
                </a>`;
			filesList.append(fileBloc);
			dt.items.add(file);
		}
		this.files = dt.files;
		filesList.find('.file-delete').on('click', function (e) {
			e.preventDefault();
			let name = $(this).prev('.file-name').text();
			$(this).parent().remove();

			for (let i = 0; i < dt.items.length; i++) {
				if (name === dt.items[i].getAsFile().name) {
					dt.items.remove(i);
					break;
				}
			}
			$(this).closest('.attachment-field').find('.file-input')[0].files = dt.files;
		});

	});
});

$(".toggle-password").on("click", function () {
	$(this).toggleClass("active");
	var input = $($(this).attr("toggle"));
	if (input.attr("type") == "password") {
		input.attr("type", "text");
	} else {
		input.attr("type", "password");
	}
});


const myCollapsible = document.getElementById('navbarToggler');
const body = document.body;
if (myCollapsible) {
	const menuWrapper = document.getElementById('menuWrapper');
	const menuInner = document.getElementById('menuInner');
	myCollapsible.addEventListener('hidden.bs.collapse', event => {
		console.log('hidden');
		body.classList.remove("overflow-hidden");
		menuInner.classList.remove("active");
		//   menuWrapper.classList.remove("active");
	})
	myCollapsible.addEventListener('shown.bs.collapse', event => {
		console.log('show');
		body.classList.add("overflow-hidden");
		menuInner.classList.add("active");
		// menuWrapper.classList.add("active");
	})
	$('#menuWrapper').on('show.bs.collapse', e => {
		$('#menuWrapper').addClass("active");
	});
	$('#menuWrapper').on('hidden.bs.collapse', e => {
		$('#menuWrapper').removeClass("active");
	});
}

document.addEventListener('DOMContentLoaded', function () {
	var chatWindow = document.querySelector('#chat');
	if (chatWindow) {
		chatWindow.scrollTop = chatWindow.scrollHeight;
	}
});

function phoneMask() {
	var num = $(this).val().replace(/[^0-9+()-]/g, '');
	$(this).val(num);
}
$('[type="tel"]').on('keyup', phoneMask);


const clearButtons = document.querySelectorAll('.clear-button');

clearButtons.forEach((button) => {
	button.addEventListener('click', (event) => {
		event.preventDefault();
		const form = button.form;

		if (form) {
			form.reset();
			const selects = form.querySelectorAll('.select-multiselect');
			selects.forEach((select) => {
				$(select).multiselect('deselectAll', true);
				$(select).multiselect('refresh');
			});
		}
		return false;
	});
});

function ShowEditFields(e) {
	const editButtons = document.querySelectorAll('.card-edit-button');
	const cancelButtons = document.querySelectorAll('.card-cancel-button');


	function deactivateOtherCards(currentCard) {
		document.querySelectorAll('.card.card-active').forEach(function (card) {
			if (card !== currentCard) {
				const areaName = card.getAttribute('data-target');
				toggleCardMode(card, areaName, false);
			}
		});
	}

	function toggleCardMode(card, areaName, isEditing) {
		const cardAboutPrev = document.querySelector(`#${areaName}Prev`);
		const cardAboutEdit = document.querySelector(`#${areaName}Edit`);
		const editButton = card.querySelector('.card-edit-button');

		if (isEditing) {
			// Edit mode
			deactivateOtherCards(card);
			if (cardAboutPrev) cardAboutPrev.classList.add("d-none");
			if (cardAboutEdit) cardAboutEdit.classList.remove("d-none");
			if (editButton) editButton.classList.add("d-none");
			card.classList.add("card-active");
		} else {
			// Preview mode
			if (cardAboutPrev) cardAboutPrev.classList.remove("d-none");
			if (cardAboutEdit) cardAboutEdit.classList.add("d-none");
			if (editButton) editButton.classList.remove("d-none");
			card.classList.remove("card-active");
		}
	}

	editButtons.forEach(function (button) {
		button.addEventListener('click', function () {
			const card = button.closest('.card');
			const areaName = card.getAttribute('data-target');
			toggleCardMode(card, areaName, true);
		});
	});

	cancelButtons.forEach(function (button) {
		button.addEventListener('click', function (e) {
			e.preventDefault();

			const card = button.closest('.card');
			const areaName = card.getAttribute('data-target');
			toggleCardMode(card, areaName, false);
		});
	});
}

ShowEditFields();


class BadgeManager {
	constructor(selector) {
		this.container = $(selector);
		this.maxbadges = (Number(this.container.data('max-badges')) + 1);
		this.bindDropdownItems();
		this.bindDeleteBadge();
	}

	bindDeleteBadge() {
		this.container.find('.badge .btn.delete').off('click').on('click', (event) => {
			event.preventDefault();
			const $badge = $(event.currentTarget).closest('.badge');
			console.log($badge.data('id'));
			const text = $badge.text().trim();
			const dataID = $badge.data('id');

			$badge.fadeOut(() => {
				$badge.remove();
				this.container.find('.dropdown-menu').append(`<li data-id="${dataID}"><a class="dropdown-item">${text}</a></li>`);
				this.bindDropdownItems();
			});
		});
	}

	bindDropdownItems() {
		this.container.find('.dropdown-menu li').off('click').on('click', (event) => {
			if (this.container.children('.badge').length < this.maxbadges) {
				const text = $(event.currentTarget).text().trim();
				const dataId = $(event.currentTarget).data('id')
				this.addBadge(text, dataId);
				$(event.currentTarget).remove();
			} else {
				console.log('Превышено максимальное количество элементов!');
			}
		});
	}

	addBadge(text, id) {
		if (this.container.children('.badge').length < this.maxbadges) {
			const $newBadge = $(BadgeManager.createBadgeHtml(text, id));
			$newBadge.hide().prependTo(this.container).fadeIn();
			this.bindDeleteBadge();
		} else {
			console.log('Превышено максимальное количество элементов!');
		}
	}

	static createBadgeHtml(text, id) {
		return `
            <span class="badge bg-light c-text br-100 fs-18 fw-medium lh-26 d-flex align-items-center gap-12" data-id="${id}">
                ${text}
                <button class="btn btn-link p-0 delete"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
				viewBox="0 0 24 24" fill="none">
			   <path d="M11.9978 1.95312C10.8928 1.95312 9.99781 2.84812 9.99781 3.95312H4.9978C4.4458 3.95312 3.9978 4.40112 3.9978 4.95312C3.9978 5.50511 4.4458 5.95311 4.9978 5.95311H18.9978C19.5498 5.95311 19.9978 5.50511 19.9978 4.95312C19.9978 4.40112 19.5498 3.95312 18.9978 3.95312H13.9978C13.9978 2.84812 13.1028 1.95312 11.9978 1.95312ZM4.9978 7.95311V17.9531C4.9978 20.1431 6.80281 21.9221 8.99781 21.9221L15.0288 21.9531C17.2238 21.9531 18.9978 20.1481 18.9978 17.9531V7.95311H4.9978ZM9.99781 10.9531C10.5498 10.9531 10.9978 11.4011 10.9978 11.9531V17.9531C10.9978 18.5051 10.5498 18.9531 9.99781 18.9531C9.44581 18.9531 8.99781 18.5051 8.99781 17.9531V11.9531C8.99781 11.4011 9.44581 10.9531 9.99781 10.9531ZM13.9978 10.9531C14.5498 10.9531 14.9978 11.4011 14.9978 11.9531V17.9531C14.9978 18.5051 14.5498 18.9531 13.9978 18.9531C13.4458 18.9531 12.9978 18.5051 12.9978 17.9531V11.9531C12.9978 11.4011 13.4458 10.9531 13.9978 10.9531Z"
					 fill="#E11010" />
		   </svg>
		   </button>
            </span>`;
	}
}


class Card {
	constructor(cardElement) {
		this.cardElement = cardElement;
		this.addButton = cardElement.querySelector('.card-add-button');
		this.removeButton = cardElement.querySelector('.card-add-soc__remove');
		this.form = this.cardElement.getElementsByTagName("form")[0];
		this.addButton.addEventListener('click', this.toggleActive.bind(this, 'add'));
		this.removeButton.addEventListener('click', this.toggleActive.bind(this, 'remove'));
	}

	toggleActive(action) {
		if (action === 'remove') {
			this.form.reset();
			if (this.cardElement.querySelector('.files-names')) {
				this.cardElement.querySelector('.files-names').innerHTML = "";
			}
		}
		this.cardElement.classList.toggle('_active');
	}
}
const cardElements = document.querySelectorAll('.card-add-soc');
cardElements.forEach(function (cardElement) {
	new Card(cardElement);
});

const vacancySocAdd = document.getElementById('vacancySocAdd')
if (vacancySocAdd) {
	let inputCount = 1;
	vacancySocAdd.addEventListener('click', function () {
		const wrapper = this.parentNode;
		const socInputs = wrapper.querySelector('.soc-inputs');
		if (inputCount < 3) {
            inputCount++;
			socInputs && socInputs.appendChild(Object.assign(document.createElement("input"), { type:"text", className:"form-control", placeholder:"Введите ссылку", name:`soclink${inputCount}` }));
        }
	} )
}

$(function () {

	console.log("ready!");
	$('.badge-container').each(function () {
		new BadgeManager(this);
	});
	$('.select-multiselect').multiselect({maxHeight: 200,numberDisplayed: 1});
	$('.select-multiselect-filter').multiselect({
		enableFiltering: true,
		maxHeight: 200,
		numberDisplayed: 1
	});

	Fancybox.bind("[data-fancybox]", {
		closeButton: false,
		Thumbs: false,
		caption: false,
		Toolbar: {
			display: false
		},
	});
	
	var swiper = new Swiper(".mySwiper", {
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
	});

	const swiper2 = new Swiper('.home-review-slider', {
		loop: true,

		navigation: {
			nextEl: '.home-review-slider-button-next',
			prevEl: '.home-review-slider-button-prev',
		},
		breakpoints: {
			320: {
				slidesPerView: 1,
				spaceBetween: 20
			},
			480: {
				slidesPerView: 2,
				spaceBetween: 20
			},
			640: {
				slidesPerView: 4,
				spaceBetween: 20
			}
		}
	});
	const swiper3 = new Swiper('.home-zapusk-slider', {
		loop: true,
		breakpoints: {
			320: {
				slidesPerView: 1,
				spaceBetween: 20
			},
			992: {
				slidesPerView: 3,
				spaceBetween: 12
			}
		},
		navigation: {
			nextEl: '.home-zapusk-slider-button-next',
			prevEl: '.home-zapusk-slider-button-prev',
		},
		pagination: {
			el: ".home-zapusk-slider-pagination",
			clickable: true
		  },
	});

});


function autocomplete(inp, arr) {
	var currentFocus;
	inp.addEventListener("input", function(e) {
		var a, b, i, val = this.value;
		closeAllLists();
		if (!val) { return false;}
		currentFocus = -1;
		a = document.createElement("DIV");
		a.setAttribute("id", this.id + " autocomplete-list");
		a.setAttribute("class", "autocomplete-items");
		this.parentNode.appendChild(a);
		for (i = 0; i < arr.length; i++) {
		  if (arr[i].substr(0, val.length).toUpperCase() == val.toUpperCase()) {
			b = document.createElement("DIV");
			b.innerHTML = "<strong>" + arr[i].substr(0, val.length) + "</strong>";
			b.innerHTML += arr[i].substr(val.length);
			b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
				b.addEventListener("click", function(e) {
				inp.value = this.getElementsByTagName("input")[0].value;
				closeAllLists();
			});
			a.appendChild(b);
		  }
		}
	});
	inp.addEventListener("keydown", function(e) {
		var x = document.getElementById(this.id + "autocomplete-list");
		if (x) x = x.getElementsByTagName("div");
		if (e.keyCode == 40) {
		  currentFocus++;
		  addActive(x);
		} else if (e.keyCode == 38) {
		  currentFocus--;
		  addActive(x);
		} else if (e.keyCode == 13) {
		  e.preventDefault();
		  if (currentFocus > -1) {
			if (x) x[currentFocus].click();
		  }
		}
	});
	function addActive(x) {
	  if (!x) return false;
	  removeActive(x);
	  if (currentFocus >= x.length) currentFocus = 0;
	  if (currentFocus < 0) currentFocus = (x.length - 1);
	  x[currentFocus].classList.add("autocomplete-active");
	}
	function removeActive(x) {
	  for (var i = 0; i < x.length; i++) {
		x[i].classList.remove("autocomplete-active");
	  }
	}
	function closeAllLists(elmnt) {
	  var x = document.getElementsByClassName("autocomplete-items");
	  for (var i = 0; i < x.length; i++) {
		if (elmnt != x[i] && elmnt != inp) {
		x[i].parentNode.removeChild(x[i]);
	  }
	}
  }
  document.addEventListener("click", function (e) {
	  closeAllLists(e.target);
  });
  } 